import React, { useState, useEffect } from 'react'
// import styled from 'styled-components'
import './admin.css'

const docUrl = 'https://www.andreamartines.it/download/doc'
const accountUrl = 'https://www.andreamartines.it/download/account'

function App() {
  const [documenti, setDocumenti] = useState({})
  const [account, setAccount] = useState({})
  const [panel, setPanel] = useState(0)

  useEffect(() => {
    readDocs()
    readAccounts()
  }, [])

  const readDocs = async () => {
    const response = await fetch(`${docUrl}/read.php`)
    const jsonData = await response.json()
    setDocumenti(jsonData)
  }
  const readAccounts = async () => {
    const response = await fetch(`${accountUrl}/read.php`)
    const jsonData = await response.json()
    setAccount(jsonData)
  }

  const createDocs = async () => {

    let formData = new FormData()
    formData.append('Nome', document.getElementById('nome').value)
    formData.append('Slug', document.getElementById('slug').value)
    formData.append(
      'File',
      document.getElementById('file').files[0],
      'titolo.pdf'
    )

    const response = await fetch(`${docUrl}/create.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
      .then((success) => {
        document.getElementById('response').innerHTML = 'OK'
        console.log(success) // Handle the success response object
      })
      .catch((error) => {
        document.getElementById('response').innerHTML = 'Errore'
        console.log(error) // Handle the error response object
      })
  }
  const onSubmit = (e) => {
    document.getElementById('response').innerHTML = ''
    e.preventDefault()
    createDocs()
  }

  const elimina = async (id) => {
    let formData = new FormData()
    formData.append('Id', id)

    const response = await fetch(`${docUrl}/delete.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
      .then((success) => {
        document.getElementById('response').innerHTML = 'Cancellato'
        console.log(success) // Handle the success response object
      })
      .catch((error) => {
        document.getElementById('response').innerHTML = 'Errore'
        console.log(error) // Handle the error response object
      })
  }
  const showEdit = (id, what) => {
    console.log(id)
    document.getElementById(`${what}-record-${id}`).classList.add('edit')
  }
  const hideEdit = (id) => {
    document.getElementById(`doc-record-${id}`).classList.remove('edit')
  }
  const edit = async (id) => {
    console.log('editing', id)
    let formData = new FormData()
    formData.append('Id', id)
    formData.append('Nome', document.getElementById(`doc-record-${id}-nome`).value)
    formData.append('Slug', document.getElementById(`doc-record-${id}-slug`).value)
    if (document.getElementById(`doc-record-${id}-file`).files.length)
      formData.append(
        'File',
        document.getElementById(`doc-record-${id}-file`).files[0],
        'titolo.pdf'
      )

    const response = await fetch(`${docUrl}/update.php`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
      .then((success) => {
        hideEdit(id)
        readDocs()
        console.log(success) // Handle the success response object
      })
      .catch((error) => {
        console.log(error) // Handle the error response object
      })
  }

  const viewDoc = async (id) => {
    var formdata = new FormData()
    formdata.append('Id', id)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }

    // console.log('documenti', documenti)
    // const nome = ''
    // documenti.records.map((documento) => {
    //   if(documento.id === id) nome = documento.nome
    // }

    fetch('https://www.andreamartines.it/download/doc/file.php', requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        const blob = new Blob([result], {
          type: 'application/pdf',
        })
        const blobUrl = URL.createObjectURL(blob)
        var nome = ''
        documenti.records.map((documento) => {
          if (documento.id === id) nome = documento.nome
        })

        var link = document.createElement('a') // Or maybe get it from the current document
        link.style = 'display: none'
        link.href = blobUrl
        link.download = `${nome}.pdf`
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(blobUrl)
      })
      .catch((error) => console.log('error', error))
  }

  return (
    <>
      <ul class="menu">
        <li className={panel === 0 && "sel"} onClick={() => setPanel(0)}>Documenti</li>
        <li className={panel === 1 && "sel"} onClick={() => setPanel(1)}>Account</li>
      </ul>
      <div id="documenti" className="panel" style={{ display: panel === 0 ? 'block' : 'none'}}>
        <div id="doclist" className="modulo">
          <header>
            <h2>Documenti</h2>
          </header>
          <table>
            {documenti.records &&
              documenti.records.map((documento) => {
                return (
                  <tr id={`doc-record-${documento.id}`} className="record">
                    <td>{documento.id}</td>
                    <td>
                      <span className="view">{documento.nome}</span>
                      <span className="edit">
                        <input
                          id={`doc-record-${documento.id}-nome`}
                          type="text"
                          defaultValue={documento.nome}
                        />
                      </span>
                    </td>
                    <td>
                      <span className="view">{documento.slug}</span>
                      <span className="edit">
                        <input
                          id={`doc-record-${documento.id}-slug`}
                          type="text"
                          defaultValue={documento.slug}
                        />
                      </span>
                    </td>
                    <td>
                      <span className="view">
                        <button
                          id={`doc-file-${documento.id}`}
                          onClick={() => viewDoc(documento.id)}
                        >
                          File
                        </button>
                      </span>
                      <span className="edit">
                        <div>{documento.nome}</div>
                        <input id={`doc-record-${documento.id}-file`} type="file" />
                      </span>
                    </td>
                    <td>
                      <span className="view">
                        <button onClick={() => showEdit(documento.id, 'doc')}>
                          Modifica
                        </button>
                      </span>
                      <span className="edit">
                        <button onClick={() => edit(documento.id)}>
                          Invia
                        </button>
                      </span>
                    </td>
                    <td>
                      <span className="view">
                        <button onClick={() => elimina(documento.id)}>
                          Elimina
                        </button>
                      </span>
                      <span className="edit">
                        <button onClick={() => hideEdit(documento.id)}>
                          Chiudi
                        </button>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </table>
        </div>
        <div id="inserisci-doc" className="modulo">
          <h2>Inserisci un documento</h2>
          <form id="formCreate" onSubmit={onSubmit}>
            <div>
              <label htmlFor="nome">Nome</label>
              <input id="nome" type="text" />
            </div>
            <div>
              <label htmlFor="titolo">Slug</label>
              <input id="slug" type="text" />
            </div>
            <div>
              <label htmlFor="file">File</label>
              <input id="file" type="file" />
            </div>
            <div className="esegui">
              <button>Aggiungi</button>
            </div>
            <div id="response"></div>
          </form>
        </div>
      </div>


      <div id="account" className="panel" style={{ display: panel === 1 ? 'block' : 'none'}}>
        <div id="accountlist" className="modulo">
          <header>
            <h2>Account</h2>
          </header>
          <table>
            {account.records &&
              account.records.map((account) => {
                return (
                  <tr id={`account-record-${account.id}`} className="record">
                    <td>{account.id}</td>
                    <td>
                      <span className="view">{account.nome}</span>
                      <span className="edit">
                        <input
                          id={`account-record-${account.id}-nome`}
                          type="text"
                          defaultValue={account.nome}
                        />
                      </span>
                    </td>
                    <td>
                      <span className="view">{account.cognome}</span>
                      <span className="edit">
                        <input
                          id={`account-record-${account.id}-cognome`}
                          type="text"
                          defaultValue={account.cognome}
                        />
                      </span>
                    </td>
                    <td>
                      <span className="view">
                        <button onClick={() => showEdit(account.id, 'account')}>
                          Modifica
                        </button>
                      </span>
                      <span className="edit">
                        <button onClick={() => edit(account.id)}>
                          Invia
                        </button>
                      </span>
                    </td>
                    <td>
                      <span className="view">
                        <button onClick={() => elimina(account.id)}>
                          Elimina
                        </button>
                      </span>
                      <span className="edit">
                        <button onClick={() => hideEdit(account.id)}>
                          Chiudi
                        </button>
                      </span>
                    </td>
                  </tr>
                )
              })}
          </table>
        </div>
        <div id="inserisci-account" className="modulo">
          <h2>Inserisci un account</h2>
          <form id="formCreate" onSubmit={onSubmit}>
            <div>
              <label htmlFor="nome">Nome</label>
              <input id="nome" type="text" />
            </div>
            <div>
              <label htmlFor="titolo">Slug</label>
              <input id="slug" type="text" />
            </div>
            <div>
              <label htmlFor="file">File</label>
              <input id="file" type="file" />
            </div>
            <div className="esegui">
              <button>Aggiungi</button>
            </div>
            <div id="response"></div>
          </form>
        </div>
      </div>      
    </>
  )
}

export default App
